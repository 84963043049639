







































































































































import { Component, Vue } from 'vue-property-decorator'
import CardWithTitle from '@/components/Card/CardWithTitle.vue'
import FSelect from '@/components/Form/FSelect.vue'
import FButton from '@/components/Button/FButton.vue'
import FCheckbox from '@/components/Form/FCheckbox.vue'
import FForm from '@/components/Form/FForm.vue'
import HelpIcon from '@/components/HelpIcon/HelpIcon.vue'
import SortableTable from '@/components/Table/SortableTable.vue'
import { SelectOption } from '@/models/SelectOption';
import { TableColumn } from '@/models/TableColumn';
import { BusyService } from '@/services/BusyService'
import { ApiService } from '@/services/ApiService'
import { ProfileService } from '@/services/ProfileService'
import {ReportPeriod} from '@/models/ReportPeriod'
import {AvailablePackageItem} from '@/models/AvailablePackageItem'
import {PackageSettings} from '@/models/PackageSettings'
import { SiteModalService } from '@/services/SiteModalService'
import { i18n } from '@/main'
import { DynamicTranslationService } from '@/services/DynamicTranslationService'

@Component({
  components: {
    CardWithTitle,
    FSelect,
    FButton,
    HelpIcon,
    SortableTable,
    FCheckbox,
    FForm
  },
  metaInfo: () => ({
    title: DynamicTranslationService.payoutDocumentsTitle
  }),
})
export default class PayoutDocuments extends Vue {
  form = {
    period: '',
    additionalDataFormats: 'none',
    otherLanguages: [],
    itemIds:[],
  }

  periodOptions: SelectOption[] = [];

  get tableColumns(): TableColumn[]{
    return [
      {
        type: 'checkbox',
        key: 'select',
      },
      {
        type: 'text',
        key: 'itemName',
        label: i18n.t('R.LIT_DocumentTitle').toString(),
        sortable: true,
        selected: true,
        bodyStyle: 'width:50%'
      },
      {
        type: 'text',
        key: 'territoryName',
        label: i18n.t('R.LIT_Region').toString(),
        sortable: true,
      },
      {
        type: 'text',
        key: 'supportsDelimited_Flat',
        label: i18n.t('R.LIT_DataFilesAvailable').toString(),
        sortable: true,
      },
      {
        type: 'text',
        key: 'languageDisplay',
        label: i18n.t('R.LIT_Languages').toString(),
        sortable: true
      }
    ]
  }

  get dataFormatOptions(): SelectOption[] {
    return [
      { value: 'none', label: 'None'},
      { value: 'csv-legacy', label: `${i18n.t('R.LIT_CSV').toString()} - ${i18n.t('R.LIT_FormatNormalized').toString()}`},
      { value: 'csv-flat', label: `${i18n.t('R.LIT_CSV').toString()} - ${i18n.t('R.LIT_FormatFlat').toString()}`},
      { value: 'csv-ansi', label: `${i18n.t('R.LIT_CSV').toString()} - ${i18n.t('R.LIT_FormatNormalized').toString()} - ${i18n.t('R.LIT_FormatMaestro').toString()}`},
      { value: 'txt-legacy', label: `${i18n.t('R.LIT_TXT').toString()} - ${i18n.t('R.LIT_FormatNormalized').toString()}`},
      { value: 'txt-flat', label: `${i18n.t('R.LIT_TXT').toString()} - ${i18n.t('R.LIT_FormatFlat').toString()}`},
    ];
  }

  languageOptions: SelectOption[] = [];

  documents: AvailablePackageItem[] | null = null;

  async mounted() {
    if (!ProfileService.profile){
      return;
    }
    BusyService.showBusy();

    const response = await ApiService.post('/api/GetPeriods',{
      organizationId: ProfileService.profile.org.id
    }) as ReportPeriod[];

    this.periodOptions = response.map(r => ({
      value: r.fiscalPeriodCode,
      label: r.displayValue
    }))

    //expecting response to return back array of activityDateRange object
    // this.periodOptions = response.activityDateRanges

    //select first one by default
    this.form.period = this.periodOptions[0].value;
    await this.onDateRangeInput();

    BusyService.hideBusy();
  }

  async onDateRangeInput() {
    if (!ProfileService.profile){
      return;
    }
    BusyService.showBusy();
    if (this.$refs.table){
      (this.$refs.table as SortableTable).unselectAll();
    }
    this.documents = await ApiService.post('/api/GetAvailablePackageItems',{
      period: this.form.period,
    }) as AvailablePackageItem[];


    this.detectLanguagesAvailable();
    BusyService.hideBusy();
  }

  detectLanguagesAvailable(){
    const languageOptions: { [key: string]: string } = {}

    if (!this.documents){
      return;
    }

    this.documents
      .filter(doc => doc.supportsAlternateLanguages && doc.languages && doc.languageDisplay)
      .forEach(doc => {
        const lang = doc.languages.split(',')
        const langDisplay = doc.languageDisplay.split(',');

        lang.forEach( (l,i) => {
          const value = l;
          const label = langDisplay[i].trim();
          languageOptions[value] = label
        })
      })
    this.languageOptions = Object.keys(languageOptions).map(k => ({
      value: k,
      label: languageOptions[k]
    }));
  }

  async onSubmit() {
    if ((this.$refs.form as FForm).hasFormError){
      return;
    }
    if (!ProfileService.profile){
      return;
    }
    if (!this.documents){
      return;
    }

    (this.$refs.form as FForm).resetValidation();

    BusyService.showBusy();

    const data: PackageSettings = {
      organizationId: ProfileService.profile.org.id,
      period: this.form.period,
      includeDataFiles: this.form.additionalDataFormats !== 'none',
      dataStyleFormat: this.form.additionalDataFormats,
      languages: this.form.otherLanguages,
      items: this.form.itemIds.map(id => ({
        itemId: id,
        itemTypeId: this.documents!.find(d => d.itemId === id)!.itemTypeId
      }))
    }

    const response = await ApiService.post('/api/RequestPackage', { packageSettings: JSON.stringify(data)})

    BusyService.hideBusy();
    SiteModalService.showModalMessage({
      title: this.$t('R.MES_DownloadIsBeingGeneratedTitle').toString(),
      message: this.$t('R.MES_DownloadIsBeingGeneratedContent').toString(),
      size: 'lg'
    });
  }
}
