














































































































import { TableColumn } from '@/models/TableColumn'
import { sortBy } from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/Pagination.vue'

@Component({ 
  components:{
    Pagination
  }
})
export default class SortableTable extends Vue {
  @Prop({ default: '' })
  emptyMsg!: string;

  @Prop()
  columns!: TableColumn[];

  @Prop({ default: true, type: Boolean })
  sortable!: boolean;

  @Prop({ default: () => ([]) })
  data!: { [key: string]: string | { value: string; sortValue: string}}[];

  @Prop()
  value !: string[];

  @Prop()
  selectId !: string;

  selectedAll = false;

  getSelectedAll() { 
    this.selectedAll = this.selected.length === this.data.length;
  }

  sortedData: { [key: string]: string | { value: string; sortValue: string}}[] = [];

  selected: string[] = this.value || [];
  unselectAll(){ 
    this.selected = [];
    this.selectedAll = false;
    this.$forceUpdate();
  }

  @Watch('selected')
  onSelectedUpdate(value: string[]){ 
    this.$emit('input', value);
  }

  toggleAll() {     
    if (!this.selectedAll){ 
      this.selected = [];
      for (let i = 0; i < this.data.length; i++) {
        this.selected.push(this.data[i][this.selectId] as string);
      }
    }else{ 
      this.selected = [];
    }
    this.$forceUpdate();
    this.getSelectedAll();
  }

  onSelect() {
    setTimeout(()=>{
      this.getSelectedAll();
    }, 25);
  }

  @Watch('sortKey')
  @Watch('sortDirAsc')
  @Watch('data')
  sortData() {
    this.sortedData = this.data.map((r, index) => (Object.assign({
      index
    }, r)))

    if (!this.data.length || !this.sortKey) {
      this.$forceUpdate()
      return
    }

    this.sortedData = sortBy(this.sortedData, (row: any) => {
      let sortText = ''

      if (this.columns.find(c => c.key === this.sortKey)?.type === 'number'){ 
        if (typeof row[this.sortKey as string] === 'string'){
          return parseInt(row[this.sortKey as string])
        }else if (typeof row[this.sortKey as string] === 'number'){
          return row[this.sortKey as string];
        }
      }

      if (row[this.sortKey as string] && row[this.sortKey as string].sortValue && typeof row[this.sortKey as string] === 'object') {
        sortText = (row[this.sortKey as string] as { value: string; sortValue: string}).sortValue
      }

      if (!sortText && typeof row[this.sortKey as string] === 'string') {
        sortText = row[this.sortKey as string] as string
      }
      return sortText
    })

    if (!this.sortDirAsc) {
      this.sortedData = this.sortedData.reverse()
    }

    this.$forceUpdate()
  }

  mounted() {
    this.sortData()
  }

  sortKey = this.columns.find(h => h.selected)?.key || null
  sortDirAsc = !this.columns.find(h => h.selected)?.descDir || false

  onHeadingClick (heading: TableColumn)  {
    if (!heading.sortable || !this.sortable) {
      return
    }

    if (this.sortKey === heading.key) {
      this.sortDirAsc = !this.sortDirAsc
    } else {
      this.sortKey = heading.key
      this.sortDirAsc = true
    }
  }
}
